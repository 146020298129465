import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import SolidHero from "../components/solid-hero/solid-hero"

const ImpressumPage = () => (
  <Layout>
    <SEO title="Impressum"/>
    <SolidHero title={"Impressum"} subtitle={() => ""} primary></SolidHero>
    <div className="_17 my-500 _4m">
      <h2>Impressum</h2>

      <p>Christian Rauscher<br/>
        Fahrschule Rauscher<br/>
        Aspacher Stra&szlig;e 53<br/>
        71522 Backnang</p>

      <h3>Kontakt</h3>
      <p>Telefon: +49 (0)173 2717781<br/>
        E-Mail: info@fahrschulerauscher.de</p>

      <h3>Umsatzsteuer-ID</h3>
      <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br/>
        DE218892410</p>

      <h3>EU-Streitschlichtung</h3>
      <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
        href="https://ec.europa.eu/consumers/odr/" target="_blank"
        rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br/> Unsere E-Mail-Adresse finden Sie oben im
        Impressum.</p>

      <h3>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h3>
      <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
        teilzunehmen.</p>

      <h3>Zentrale Kontaktstelle nach dem Digital Services Act - DSA (Verordnung (EU) 2022/265)</h3>
      <p>Unsere zentrale Kontaktstelle f&uuml;r Nutzer und Beh&ouml;rden nach Art. 11, 12 DSA erreichen Sie wie
        folgt:</p>
      <p>E-Mail: info@fahrschulerauscher.de<br/>
        Telefon: +49 (0)173 2717781
      </p>
      <p>Die für den Kontakt zur Verf&uuml;gung stehenden Sprachen sind: Deutsch, Englisch.</p>
    </div>
  </Layout>
)

export default ImpressumPage
